export function setLocalStorage (key, value) {
  localStorage.setItem(key, value)
}
export function getLocalStorage (key) {
  const res = localStorage.getItem(key)
  if (res) {
    return res
  } else {
    return ''
  }
  // return localStorage.getItem(key)
}
