import axios from 'axios'
// 这边单独引入一个Toast vue.use以后此处引用不到
import { Toast } from 'vant'
import { setCookieDefault } from './cookie'
//  request 拦截器
axios.interceptors.request.use(
  config => {
    Toast.loading({
      message: '加载中',
      forbidClick: true
    })
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
//  response拦截器
axios.interceptors.response.use(
  response => {
    Toast.clear()
    const status = response.data.status
    if (status === -99) {
      // 没有授权，获取当前的路由链接，确保回调二次授权以后回调到当前页面
      console.log('后台授权已经失效 1')
      // const currentUrl = window.location.href
      // const pathArr = currentUrl.split('#')
      // console.log(pathArr[1])
      // setLocalStorage('beforeLoginUrl', pathArr[1])
      // const url = process.env.VUE_APP_AUTH_URL
      // window.location.href = url
      setCookieDefault('userAuth', 0)
      window.location.reload()
      // router.replace('/refreshRouter')
    } else if (status === -98) {
      // 服务繁忙
    }
    return response
  },
  error => {
    return error
  }
)

export default axios
