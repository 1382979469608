export function getCookie (name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  if (arr) {
    return unescape(arr[2])
  } else {
    return ''
  }
}

// export function getCookie (name) {
//   if (document.cookie.length > 0) {
//     let start = document.cookie.indexOf(name + '=')
//     if (start !== -1) {
//       start = start + name.length + 1
//       let end = document.cookie.indexOf(';', start)
//       if (end === -1) end = document.cookie.length
//       return unescape(document.cookie.substring(start, end))
//     }
//   }
//   return ''
// }

/**
 * 设置缓存，主要用于购物车和seesionid的设置
 * @param key 键
 * @param value 值
 * @param days 缓存天数，默认3天
 */
export function setCookie (key, value, days = 3) {
  const date = new Date()
  date.setTime(date.getTime() + (days * 24 * 3600 * 1000))
  // console.log('date => ' + date)
  const expires = date.toGMTString()
  // console.log('过期时间 => ' + expires)
  document.cookie = key + '=' + value + '; expires=' + expires
}

/**
 * 设置常规cookie，有效期就是session，跟随seesion
 * @param key
 * @param value
 */
export function setCookieDefault (key, value) {
  document.cookie = key + '=' + value
}
