<template>
    <div id="app">
        <!--    <transition name="van-slide-left">-->
        <keep-alive include="Search,Home">
            <router-view/>
        </keep-alive>
        <!--    </transition>-->
    </div>
</template>

<script>
  export default {}
</script>

<style>

</style>
