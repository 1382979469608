import Vue from 'vue'
import { Divider, Grid, GridItem, Image, Icon, Calendar, Tab, Tabs, Toast, NumberKeyboard, Popup, List, Dialog, Field, Button, Overlay, AddressEdit, Area, Rate, Uploader, Search, Swipe, SwipeItem, CountDown, Stepper, Progress, ActionSheet } from 'vant'

// ImagePreview()

Vue.use(Divider)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Image)
Vue.use(Icon)
Vue.use(Calendar)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Toast)
Vue.use(NumberKeyboard)
Vue.use(Popup)
Vue.use(List)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(Button)
Vue.use(Overlay)
Vue.use(AddressEdit)
Vue.use(Area)
Vue.use(Rate)
Vue.use(Uploader)
Vue.use(Search)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(CountDown)
Vue.use(Stepper)
Vue.use(Progress)
Vue.use(ActionSheet)
