export const checkRouter = [
  {
    path: '/check/index',
    name: '首页',
    component: () => import('../views/check/Index.vue')
  },
  {
    path: '/check/login',
    name: '登录页面',
    component: () => import('../views/check/Login.vue')
  },
  {
    path: '/check/orderList',
    name: '订单页面',
    component: () => import('../views/check/OrderList.vue')
  },
  {
    path: '/check/count',
    name: '统计页面',
    component: () => import('../views/check/Count.vue')
  },
  {
    path: '/check/warning/:msg',
    name: '警告页面',
    component: () => import('../views/check/Warning.vue')
  },
  {
    path: '/check/scan',
    name: '扫码',
    component: () => import('../views/check/Scan.vue')
  },
  {
    path: '/check/check/:type/:id/:sign?',
    name: '核销',
    component: () => import('../views/check/Check.vue')
  },
    {
        path: '/check/yard',
        name: '核销',
        component: () => import('../views/check/Yard.vue')
    },
    {
        path: '/check/checkList/:type/:id/:sign?',
        name: '核销列表',
        component: () => import('../views/check/CheckList.vue')
    },
    {
        path: '/check/checkShop',
        name: '核销店铺统计',
        component: () => import('../views/check/CheckShop.vue')
    },
    {
        path: '/check/reserveList',
        name: '预约列表',
        component: () => import('../views/check/ReserveList.vue')
    },
    {
        path: '/check/checkReserve/:id',
        name: '预约核销',
        component: () => import('../views/check/CheckReserve.vue')
    }
]
