import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 最后一次下单的url，方便地址和优惠券页面回跳回去
    lastOrderRoute: '',
    beforeUrl: ''
  },
  mutations: {
    setLastOrderRoute (state, status) {
      this.state.lastOrderRoute = status
    },
    setBeforeUrl (state, status) {
      this.state.beforeUrl = status
    }
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
