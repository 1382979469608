import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
// import store from '../store/index'
import { getLocalStorage, setLocalStorage } from '../utils/localStorage'
import { distributionRouter } from './distribution'
import { checkRouter } from './check'
import { getCookie } from '../utils/cookie'
// import { globalConfig, UnionPayJsdk, WXJsdk } from '../utils/mixin'
import { globalConfig, WXJsdk } from '../utils/mixin'
import { setShopTitle } from '../utils/tools'
// import { WXJsdk } from '../utils/mixin'
// import { setShopTitle } from '../utils/tools'

Vue.use(VueRouter)

const baseRouter = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth/Auth.vue')
  },
  {
    path: '/refreshRouter',
    name: 'refresh',
    component: () => import('../views/common/RefreshRouter.vue')
  },
  {
    path: '/authNotify/:phpsessid?',
    name: 'authNotify',
    component: () => import('../views/auth/AuthNotify.vue')
  },
  {
    path: '/',
    name: 'home',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import('../views/store/Home.vue')
  },
  {
    path: '/user',
    name: '个人中心',
    component: () => import('../views/store/User.vue')
  },
  {
    path: '/card',
    component: () => import('../views/store/Card.vue')
  },
  {
    name: 'search',
    path: '/search/:stationId?',
    component: () => import('../views/store/Search.vue')
  },
  {
    path: '/cardList',
    name: '我的碗卡',
    component: () => import('../views/store/CardList.vue')
  },
  {
    path: '/orderList/:status?',
    name: '我的订单',
    component: () => import('../views/store/OrderList.vue')
  },
  {
    path: '/guidPft',
    component: () => import('../views/store/GuidPft.vue')
  },
  {
    path: '/bind',
    name: '绑定注册页',
    component: () => import('../views/store/Bind.vue')
  },
  {
    path: '/hotOrder',
    name: '热门餐厅订单',
    component: () => import('../views/store/HotOrder.vue')
  },
  {
    path: '/activation/:card?/:password?',
    name: '碗卡激活页',
    component: () => import('../views/store/Activation.vue')
  },
  {
    path: '/couponList',
    name: '优惠券列表',
    component: () => import('../views/store/CouponList.vue')
  },
  {
    path: '/coupon/:id',
    component: () => import('../views/store/Coupon.vue')
  },
  {
    path: '/addressList/:key?',
    name: '收货地址列表',
    component: () => import('../views/store/AddressList.vue')
  },
  {
    path: '/addressEdit/:id?',
    component: () => import('../views/store/AddressEdit.vue')
  },
  {
    path: '/problem',
    name: '常见问题',
    component: () => import('../views/store/Problem.vue')
  },
  {
    path: '/order/:id',
    component: () => import('../views/store/Order.vue')
  },
  {
    path: '/commentList/:type?',
    component: () => import('../views/store/CommentList.vue')
  },
  {
    path: '/comment/:id',
    component: () => import('../views/store/Comment.vue')
  },
  {
    path: '/bookingList',
    component: () => import('../views/store/BookingList.vue')
  },
  {
    path: '/hotList',
    component: () => import('../views/store/HotList.vue')
  },
  {
    path: '/groups/:id',
    component: () => import('../views/store/Groups.vue')
  },
  {
    path: '/seckillGroup/:id',
    component: () => import('../views/store/SeckillGroup.vue')
  },
  {
    path: '/salePoster',
    component: () => import('../views/store/SalePoster.vue')
  },
  {
    path: '/product/:id',
    component: () => import('../views/store/Product.vue')
  },
  {
    path: '/goodsOrder',
    component: () => import('../views/store/GoodsOrder.vue')
  },
  {
    path: '/payment/:orderId',
    component: () => import('../views/store/Payment.vue')
  },
  {
    path: '/selectCoupon',
    component: () => import('../views/store/SelectCoupon.vue')
  },
  {
    path: '/square',
    component: () => import('../views/store/Square.vue')
  },
  {
    path: '/test',
    component: () => import('../views/store/Test.vue')
  },
  {
    path: '/cart',
    component: () => import('../views/store/Cart.vue')
  },
  {
    path: '/honor/list',
    component: () => import('../views/honor/List.vue')
  },
  {
    path: '/honor/store/:id',
    component: () => import('../views/honor/Store.vue')
  },
  {
    path: '/honor/comment/:id/:type?',
    component: () => import('../views/honor/Comment.vue')
  },
  {
    path: '/bindysf',
    name: '云闪付绑定注册页',
    component: () => import('../views/store/Bindysf.vue')
  },
  {
    path: '/honor/newlist',
    component: () => import('../views/honor/Newlist.vue')
  },
    {
        path: '/honor/newstore/:id',
        component: () => import('../views/honor/NewStore.vue')
    },
    {
        path: '/subscribe/details/:id',
        component: () => import('../views/subscribe/Details.vue')
    },
    {
        path: '/subscribe/subscribe/:id/:orderid?',
        component: () => import('../views/subscribe/Subscribe.vue')
    },
    {
        path: '/subscribe/list',
        component: () => import('../views/subscribe/List.vue')
    }
]

const routes = baseRouter.concat(distributionRouter, checkRouter)

const router = new VueRouter({
  routes
})

// 注册一个导航守卫，进行授权判断
// 1.已经登录则执行next()
// 2.没有登录跳转中央授权组件
router.beforeEach((to, from, next) => {
  console.log('路由捕捉', from)
  store.commit('setBeforeUrl', from)
  const userAuth = getCookie('userAuth')
  // 前两个条件代表进入商城业务页面，后一个条件代表没有授权
  if (to.name !== 'auth' && to.name !== 'authNotify' && (userAuth == 0)) {
    setLocalStorage('beforeLoginUrl', to.fullPath)
    next({
      name: 'auth'
    })
  } else if ((to.name === 'auth' || to.name === 'authNotify') && (userAuth == 1)) {
    // 如果进入授权或者回调页面，并且当前已经登录 这个情况包含axios请求status -99的情况
    next({
      name: 'home'
    })
  } else {
    // 这边主要要处理【已经授权再次进入页面的情况】不会进入回调，所以很多操作需要在这边设置，不能在回调一次性设置
    // 当前已经授权了，不会去回调组件，这边设置一下Title
    setShopTitle(getLocalStorage('shopName'))
    // 每进入一个页面，都默认设置分享配置
    const currentBrowser = getLocalStorage('currentBrowser')
    if (currentBrowser === 'weixin') {
      console.log('走微信配置')
      WXJsdk.methods.localStorageWxConfig()
      WXJsdk.methods.WxShareConfig('一碗商城', '好吃好玩，品质生活一键get！', globalConfig.data().webUrl + '#' + to.path, 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/20201010/8221bbf6f9ccc163d0d8fdee03c80440.jpg')
    } else if (currentBrowser === 'unionpay') {
      // UnionPayJsdk.methods.localStorageUnionPayConfig()
      console.log('走云闪付配置')
    }
    next()
  }
})

export default router
