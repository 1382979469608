export const distributionRouter = [
  {
    path: '/distribution',
    name: '分销主页面',
    component: () => import('../views/distribution/index.vue')
  },
  {
    path: '/distribution/money',
    name: '分销佣金页面',
    component: () => import('../views/distribution/Money.vue')
  },
  {
    path: '/distribution/achievement',
    name: '分销业绩统计页',
    component: () => import('../views/distribution/Achievement.vue')
  },
  {
    path: '/distribution/invite',
    name: '分销邀请页面',
    component: () => import('../views/distribution/Invite.vue')
  },
  {
    path: '/distribution/poster',
    name: '分销邀请海报页面',
    component: () => import('../views/distribution/Poster.vue')
  },
  {
    path: '/distribution/apply/:channel',
    name: '分销申请页面',
    component: () => import('../views/distribution/Apply.vue')
  },
  {
    path: '/distribution/rule',
    name: '分销规则',
    component: () => import('../views/distribution/Rule.vue')
  }
]
