import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/axios'
import VueAxios from 'vue-axios'

import './assets/styles/global.css'
import './assets/responsive'
import './utils/vant'

//  云闪付相关
import VueCupUi from 'vue-cup-ui'
import 'vue-cup-ui/lib/vue-cup-ui.css'
Vue.use(VueCupUi)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

console.log('main ----------  >   1111111111111111')
Vue.use(VueAxios, axios)
