import store from '../store'

import { getLocalStorage, setLocalStorage } from './localStorage'
import { getCookie } from './cookie'

/**
 * 获取标准化时间日期
 * @param day
 * @returns {string}
 * 传入0：获取今天的日期2021-03-19  传入1获取明天的日期2021-03-20  传入-1获取昨天的日期2021-03-18 以此类推
 */
export function getStandardDate (day) {
  var dd = new Date()
  if (day) {
    dd.setDate(dd.getDate() + day)
  }
  var m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
  var y = dd.getFullYear()
  return y + '-' + m + '-' + d
}

/**
 * 格林威治日期转标准日期
 * @param date
 * @returns {string}
 *  Fri Mar 19 2021 15:09:47 GMT+0800 (中国标准时间)  => 2021-3-19
 *  Sat Mar 20 2021 15:09:47 GMT+0800 (中国标准时间)  => 2021-3-20
 */
export function formatGMT2StandardDate (date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

/**
 * 判断标准时间是否过期 2021-03-24 11:00:00
 * @param time
 * @returns {boolean}
 */
export function standardTimeIsOver (time) {
  var isTime = new Date(time.replace(/-/g, '/')).getTime()
  var timestamp = Date.parse(new Date())
  if ((isTime - timestamp) < 0) {
    return true
  } else {
    return false
  }
}

/**
 * 标准时间转时间戳
 * 这个方法获取到的时间戳，比后端多了3个0
 * @param e
 * @returns {number}
 */
export function standardTime2Timestamp (e) {
  let time = Date.parse(e.replace(/-/g, '/'))
  time = time.toString()
  time = time.substr(0, 10)
  return parseInt(time)
}

/**
 * 获取当前时间戳
 * @returns {string}
 */
export function getCurrentTimestamp () {
  let time = Date.parse(new Date())
  time = time.toString()
  time = time.substr(0, 10)
  return parseInt(time)
}

//  获取当前星期几
export function getCurrentWeek () {
  var tempDate = new Date()

  var days = tempDate.getDay()
  var week

  switch (days) {
    case 1:
      week = '星期一'
      break
    case 2:
      week = '星期二'
      break
    case 3:
      week = '星期三'
      break
    case 4:
      week = '星期四'
      break
    case 5:
      week = '星期五'
      break
    case 6:
      week = '星期六'
      break
    case 0:
      week = '星期日'
      break
  }
  return week
}

/**
 * 分转元
 * @param money
 * @returns {string}
 */
export function moneyFen2Yuan (money) {
  const yuan = money / 100
  return yuan.toFixed(2)
}

/**
 * 获取url地址里面的get参数
 * @param key
 * @returns {string|null}
 */
export function getUrlParams (key) {
  const url = document.location.toString()
  const paramsArr = url.split('?')
  const paramsStr = paramsArr[1]
  // console.log(paramsStr)
  let goal = ''
  if (paramsStr) {
    const paramsKeyValArr = paramsStr.split('&')
    paramsKeyValArr.forEach(function (val, index, arr) {
      const params = val.split('=')
      if (params[0] === key) {
        goal = params[1]
      }
    })
    return goal
  } else {
    return ''
  }
}

/**
 * 复制字符串到粘贴板
 * @param textString
 */
export function string2Clipboard (textString) {
  const input = document.createElement('textarea')
  input.id = 'copy-input'
  input.readOnly = 'readOnly' // 防止ios聚焦触发键盘事件
  input.style.position = 'absolute'
  input.style.left = '-1000px'
  input.style.zIndex = '-1000'
  document.body.appendChild(input)
  input.value = textString
  const textbox = input
  const startIndex = 0
  const stopIndex = textString.length
  if (textbox.createTextRange) {
    const range = textbox.createTextRange()
    range.collapse(true)
    range.moveStart('character', startIndex)
    range.moveEnd('character', stopIndex - startIndex)
    range.select()
  } else {
    textbox.setSelectionRange(startIndex, stopIndex)
    textbox.focus()
  }
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    this.$toast('复制成功')
  } else {
    this.$toast('复制失败')
  }
}

/**
 * 订单状态 中文
 * @param type
 * @param status
 * @returns {string}
 */
export function orderStatusStr (type, status) {
  let orderStatus = ''
  switch (status) {
    case 0:
      if (type === '1') {
        orderStatus = '待收货'
      } else if (type === '5') {
        orderStatus = '未预约'
      } else {
        orderStatus = '未使用'
      }
      break
    case 1:
      orderStatus = '使用中'
      break
    case 2:
      if (type === '1') {
        orderStatus = '已收货'
      } else if (type === '5') {
        orderStatus = '已预约'
      } else {
        orderStatus = '已使用'
      }
      break
    case 3:
      orderStatus = '已评论'
      break
    case -1:
      orderStatus = '已失效'
      break
    case -2:
      orderStatus = '已退款'
      break
    case -3:
      orderStatus = '退款处理中'
      break
    default:
      break
  }
  return orderStatus
}

/**
 * 自动识别跳转，主要用在 =》 首页
 * 判断链接是否属于系统内部链接，如果是内部链接则push避免重新授权，如果是外部链接则window.location.href
 * 使用的时候需要混入组件的methods中才可以使用this.$router
 * @param url 传入链接
 */
export function hrefUrlCommon (url) {
  if (url) {} else {
    return
  }
  const index = url.indexOf(process.env.VUE_APP_WEB_URL + '#')
  if (index >= 0) {
    // 跳转到了系统内部的链接，自动给他转换成
    const arr = url.split('#')
    if (arr[1]) {
      // 路由跳转
      this.$router.push(arr[1])
    } else {
      window.location.href = url
    }
  } else {
    window.location.href = url
  }
}

/**
 * 获取站点信息
 * @returns {string|number}
 */
export function getStationId () {
  const urlStationId = getUrlParams('stationId')
  // 设置进缓存，然后返回
  if (urlStationId) {
    setLocalStorage('stationId', urlStationId)
    return urlStationId
  } else {
    // 获取上一次进入站点
    const storageStationId = getLocalStorage('stationId')
    if (storageStationId) {
      return storageStationId
    } else {
      // 直接默认1
      setLocalStorage('stationId', 1)
      return 1
    }
  }
}

export function getChannelId () {
  console.log(store.state)
  // 是否是分销员，自购优先级最高
  const urlChannelId = getUrlParams('channel')
  if (urlChannelId) {
  }
}

/**
 * 设置url的value
 * @param url
 * @param key
 * @param value
 * @returns {string}
 */
export function urlSetValue (url, key, value) {
  const hasParame = url.split('?')
  if (hasParame[1]) {
    // 有参数，要确定一下参数里面有没有要设置的这个key
    const parameKeyValueArr = hasParame[1].split('&')
    // 直接把参数转成数组
    const parameArr = []
    parameKeyValueArr.forEach(function (value, index, array) {
      const csplit = value.split('=')
      parameArr[csplit[0]] = csplit[1]
    })
    // 设置key值
    parameArr[key] = value
    // 转成参数字符串
    var parameStr = ''
    for (var objKey in parameArr) {
      parameStr = parameStr + '&' + objKey + '=' + parameArr[objKey]
    }
    // 去除头一个字符
    parameStr = parameStr.substr(1)
    return hasParame[0] + '?' + parameStr
  } else {
    // 没有任何参数，可以直接设置
    return url + '?' + key + '=' + value
  }
}

/**
 * 获取一个数字数组里的最大值和最小值
 * @param arr
 * @returns {number[]}
 */
export function getBetweenNumber (arr) {
  let min = 0
  let max = 0
  arr.forEach(function (value, index, array) {
    if (index === 0) {
      min = value
      max = value
    } else {
      if (value < min) {
        min = value
      }
      if (value > max) {
        max = value
      }
    }
  })
  return [min, max]
  // console.log('最小' + min)
  // console.log('最大' + max)
}

/**
 * 处理分销的参数，这个函数使用在商品详情、分组、秒杀专区、专区等和 “分销分享” 有关的地方
 * 获取channel，设置srcChannel记录最新一次购买的来源渠道
 * 注，urlChannel这个参数无论如何都会保留，因为需要传给后端，可能出现urlChannel = 100 ， 然后最后分销在自己头上的情况，属于正常
 */
export function getChannelParams () {
  const urlChannel = getUrlParams('channel')
  setLocalStorage('urlChannel', urlChannel) // 保存urlChannel支付的时候传给后台保留
  if (getLocalStorage('channelSource') === '自购') {
    console.log('自购不设置')
    return
  }
  if (urlChannel) {
    setLocalStorage('srcChannel', urlChannel) // 新版最终用这个。设置到localstorage里面，为了让localstorage也拥有一个session的生存周期，需要在notify设置权限的时候初始化一下！
    setLocalStorage('lsSrcChannelExpire', getStandardDate(3) + ' 23:59:59')
    setLocalStorage('channelSource', '抢单')
  } else {
    // 这边不对空参数进行处理的原因：用户可能用分销链接进来，然后出去外面乱点，再点进来的时候可能是“商城链接”是不带参数的，所以会跑单；这边直接设置一个session生存期的有效时间，比较合理，在这个生存期内分销都有效，
  }
}

/**
 * 店铺名有初始化，并且在分组页面和详情页面可能会被更新；
 * 主要是用来提醒用户当前会是谁的单子；
 */
export function setShopTitle (currentShopName) {
  setLocalStorage('shopName', currentShopName)
  //  为商城名加上【一碗福州】前缀
  if (currentShopName === '一碗福州') {
    document.title = currentShopName
  } else {
    document.title = '【一碗福州官方】' + currentShopName
  }
}

/**
 * 性能优化  = 》  生成要传到后端的srcChannel
 * 如果当前已经是分销员，那么就不传，如果当前不是分销员，获取到srcChannel就传过去
 * @returns {string}
 */
export function getPostSrcChannel () {
  let apiPostSrcChannel = ''
  const isDistributionMember = getCookie('isDistributionMember')
  if (isDistributionMember == 1) {
    apiPostSrcChannel = ''
  } else {
    apiPostSrcChannel = getLocalStorage('srcChannel')
  }
  console.log('postSrchannel = ' + apiPostSrcChannel)
  return apiPostSrcChannel
}

/**
 * authNotify  - 初始化srcChannel
 * 需要注意的参数srcChannel、shopName、channelSource、lsSrcChannelExpire
 * 1.自购 ( 前置，进入商城之前就可以通过api判断 )
 * 2.实时抢粉 ( 后置，进入商城之后才可能发生 )
 * 3.海报扫码 ( 前置，进入商城之前就可以通过api判断 )
 * 4.临时粉丝 ( 前置，进入商城之前就可以通过api判断 )
 * 5.锁粉 ( 前置，进入商城之前就可以通过api判断 )
 * 6.官方粉丝 ( 前置，进入商城之前就可以通过api判断 )
 */
export function initSrcChannel (apiSrcChannel, apiShopName) {
  const channelSource = getLocalStorage('channelSource')
  if (channelSource === '自购') {
    setLocalStorage('shopName', apiShopName)
    setLocalStorage('srcChannel', apiSrcChannel)
    return
  }
  if (channelSource === '扫码') {
    setLocalStorage('shopName', apiShopName)
    setLocalStorage('srcChannel', apiSrcChannel)
    return
  }
  //  三天内进入过分销的链接
  const lsSrcChannelExpire = getLocalStorage('lsSrcChannelExpire')
  if (lsSrcChannelExpire) {
    if (standardTimeIsOver(lsSrcChannelExpire)) {
      // setLocalStorage('lsSrcChannelExpire', '')
    } else {
      setLocalStorage('channelSource', '临时粉丝')
      return
    }
  }
  if (channelSource === '锁粉') {
    setLocalStorage('shopName', apiShopName)
    setLocalStorage('srcChannel', apiSrcChannel)
    return
  }
  if (channelSource === '') {
    setLocalStorage('shopName', apiShopName)
    setLocalStorage('srcChannel', apiSrcChannel)
    return
  }
  console.log('1')
}
